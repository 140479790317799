
export default {
  // head() {
  //     return {
  //         title: 'The Chat-based Hiring App Specializing in Sales | Post Jobs for free - Hirey',
  //         meta: [
  //             {hid: 'description', name: 'description', content: 'Hirey is the first chat-based hiring app which matches recruiters and vetted candidates directly. Employers can post jobs and hire candidates free.'}
  //         ]
  //     }
  // },
  data() {
    return {
      scrollTop: 0
    }
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll)
    this.listenerFunction()
  },
  destroyed() {},
  beforeDestroy() {
    this.beforeDestroy()
  },
  methods: {
    handleScroll() {
      Math.floor(document.documentElement.scrollTop || document.body.scrollTop);
    },
    listenerFunction(e) {
      document.addEventListener("scroll", this.handleScroll, true);
    },
    beforeDestroy() {
      document.removeEventListener("scroll", this.listenerFunction);
    },
    goTop(){
      this.$refs.scrollBox.scrollTop = 0
    }
  }
}
