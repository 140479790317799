
import {send as logSend, triggerPV, logMixin} from 'smith-log';

export default {
  name: 'Homepage',
  components: {},
  mixin: [logMixin],
  data() {
    return {
      activeIndex: '1',
      navItems: [
        {
          "name": "home",
          "label": "Home",
          "route": "/",
        },
        {
          "name": "about",
          "label": "About Product",
          "route": "/homepage",
        },
        {
          "name": "jobseeker",
          "label": "For Job Seekers",
          "route": "/jobseeker",
          "eventTracking": "e_re_website_jobseeker"
        },
        {
          "name": "recruiter",
          "label": "For Employers",
          "route": "/recruiter",
          "eventTracking": "e_ca_website_recruiter"
        },
        {
          "name": "reviews",
          "label": "Reviews",
          "route": "/hirey-reviews",
        },
        {
          "name": "blogs",
          "label": "Blogs",
          "route": "/blog/list",
        },
      ],
      activeNav: 'home',
      isRecruiter: true,
      pageName: '/'
    }
  },
  computed: {},
  fetch() {
    // console.log('====>', window.location.href.indexOf('/recruiter'))
    // if (this.$route.path.indexOf('/recruiter') > -1) {
    //   this.switchBthText = "I'm a Job Seeker"
    //   this.isRecruiter = true
    //   this.isRecruiters = true
    //   this.isHomepage = false
    //   this.isDownload = false
    //   this.isReviews = false
    //   // this.pageName = '/recruiter'
    // } else if (this.$route.path.indexOf('/jobseeker') > -1) {
    //   this.switchBthText = "I'm a Recruiter"
    //   this.isRecruiter = false
    //   this.isJobSeeker = true
    //   this.isHomepage = false
    //   this.isDownload = false
    //   this.isReviews = false
    //   // this.pageName = '/jobseeker'
    // } else if (this.$route.path.indexOf('/hirey-reviews') > -1 || this.$route.path.indexOf('/hirey-app-reviews') > -1) {
    //   // this.switchBthText = "I'm a Recruiter"
    //   this.isRecruiter = false
    //   this.isJobSeeker = false
    //   this.isHomepage = false
    //   this.isDownload = false
    //   this.isReviews = true
    //   // this.pageName = '/reviews'
    // }
    // if (this.$route.path.indexOf('/download') > -1) {
    //   // this.pageBthDownload()
    //   this.isHome = false
    //   this.$router.push('/download')
    //   this.isHomepage = false
    //   this.isDownload = true
    // }
    // if (this.$route.path.indexOf('/homepage') > -1) {
    //   // this.pageBthDownload()
    //   this.isHome = true
    //   this.$router.push('/homepage')
    //   this.isHomepage = false
    //   this.isDownload = false
    // }
    // if (this.$route.path.indexOf('/job/') > -1) {
    //   this.isHome = true
    //   this.isHomepage = false
    //   this.isDownload = false
    // }
    // if (this.$route.path.indexOf('/apply/') > -1) {
    //   // this.pageBthDownload()
    //   this.isHome = true
    //   this.isHomepage = false
    //   this.isDownload = false
    // }
  },
  watch: {
    '$route.path': function (newPath) {
      // console.log("route path change >>>>", newPath)
      this.setActiveNav(newPath)
    },
    activeNav: function (nav) {
      // console.log("watch nav changed", nav)
      if (nav === 'recruiter') {
        this.isRecruiter = true
      } else if (nav === 'jobseeker') {
        this.isRecruiter = false
      }
    }
  },
  mounted() {
    window.addEventListener('popstate', this.popstate, false)
    // console.log("mounted set nav")
    this.setActiveNav(this.$route.path);
  },
  destroyed() {
    window.removeEventListener('popstate', this.popstate, false)
  },
  methods: {
    setActiveNav(routePath) {
      // console.log("current rout path", routePath)
      if (routePath === '/recruiter') {
        this.activeNav = "recruiter"
      } else if (routePath === '/jobseeker') {
        this.activeNav = "jobseeker"
      } else if (routePath === '/') {
        this.activeNav = "home"
      } else if (routePath === '/hirey-reviews' || routePath === '/hirey-app-reviews') {
        this.activeNav = "reviews"
      } else if (routePath === '/homepage') {
        this.activeNav = "about"
      } else if (routePath === '/blog/list') {
        this.activeNav = "blogs"
      }
      // console.log("active nav", this.activeNav)
    },
    downloadApp() {
      if (this.isRecruiter) {
        logSend('MC', 'e_re_website_download', {
          region_id: 1
        })
      } else {
        logSend('MC', 'e_ca_website_download', {
          region_id: 1
        })
      }
      this.pageBthDownload()
    },
    logoBth() {
      this.$router.push('/')
    },
    pageBthDownload() {
      if (window.location.href.indexOf('/recruiter') > -1) {
        this.pageName = '/recruiter'
      } else if (window.location.href.indexOf('/jobseeker') > -1) {
        this.pageName = '/jobseeker'
      }
      window.sessionStorage.setItem('page', this.pageName)
      this.$router.push('/download')
      this.activeNav = 'download'
    },
    popstate() {
      if (window.location.href.indexOf('/recruiter') > -1) {
        this.isRecruiter = true
      }
      if (window.location.href.indexOf('/jobseeker') > -1) {
        this.isRecruiter = false
      }
    },
    goWebapp() {
      window.open('https://www.hirey.com/web/for-you', '_self')
    },
    selectNav(e) {
      this.activeNav = e.name;
      if (e.eventTracking) {
        logSend('MC', e.eventTracking, {
          region_id: 1
        })
      }
      this.$router.push(e.route);
    }
  }

}
